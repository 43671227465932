<template>
    <div>
        <div class="dabox" v-if="showSecond">
            <div class="leftbox">
                <Card class="buttonCss" dis-hover :bordered="false">
                    <Button class="operationBtn" @click="backOut">返回</Button>
                </Card>
            </div>
            <div class="right-box">
                <div v-if="img_list.length > 0">
                    <div v-for="item in img_list" class="bg-img" :key="item">
                        <img :src="item + '?v=' + timer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var that;
import { getpdf } from '../../api/interFace'

export default {
    name: 'allcontracts',
    props: {
        titleSync: Object
    },
    data() {
        return {
            data: {},
            list: [],
            template_html_content: "",
            img_list: [],
            timer: '48',
            needCheck: true,
            downPath: '',
            showSecond: false
        }
    },
    created() {
        that = this;
        this.timer = Date.parse(new Date());
    },
    methods: {
        backOut() {
            this.showSecond = false
            this.$emit('titleSync', false)
        },
        openIt(e) {
            this.showSecond = true
            this.img_list = e.contract_imgs || []
            this.downPath = e.contract_path
            this.needId = e.id
        },
        resetWidth() {
            this.titleSync.arr.participant.forEach(function (v, k, arr) {
                if (v.position_pagenum > 0) {
                    v.positionY = Number(v.positionY) + ((v.position_pagenum - 1) * 1147)
                }
                if (v.positionfirm_pagenum > 0) {
                    v.positionfirmY = Number(v.positionfirmY) + ((v.positionfirm_pagenum - 1) * 1147)
                }
                if (arr[k].yinzhang != null && arr[k].yinzhang != "") {
                    let img = new Image();
                    img.src = arr[k].yinzhang;
                    if (img.complete) {
                        arr[k].yinzhang_width = img.width / 3;
                        arr[k].yinzhang_height = img.height / 3;
                    } else {
                        // 加载完成执行
                        img.onload = function () {
                            arr[k].yinzhang_width = img.width / 3;
                            arr[k].yinzhang_height = img.height / 3;
                        };
                    }
                }
            })
            setInterval(function () {
                that.list = that.titleSync.arr.participant;
            }, 100)
        },
        resetWidthElse() {
            this.titleSync.arr.participant.forEach(function (v, k, arr) {
                if (arr[k].yinzhang != null && arr[k].yinzhang != "") {
                    let img = new Image();
                    img.src = arr[k].yinzhang;
                    if (img.complete) {
                        arr[k].yinzhang_width = img.width / 3;
                        arr[k].yinzhang_height = img.height / 3;
                    } else {
                        // 加载完成执行
                        img.onload = function () {
                            arr[k].yinzhang_width = img.width / 3;
                            arr[k].yinzhang_height = img.height / 3;
                        };
                    }
                }
            })
            setInterval(function () {
                that.list = that.titleSync.arr.participant;
            }, 100)
        },
        download() {
            if (!that.needCheck) {
                return that.$Message.error('请勿重复下载')
            }
            that.$Message.loading({
                content: '生成中...',
                duration: 0
            })
            if (this.downPath) {
                that.$Message.destroy()
                window.open(this.downPath)
                that.needCheck = true
                return
            }
            that.needCheck = false
            getpdf({ id: this.needId }).then(res => {
                that.$Message.destroy()
                window.open(res.data)
                that.needCheck = true
            }).catch(res => {
                console.log(res)
                that.needCheck = true
            })
        }
    }
}

</script>

<style scoped>
.dabox {
    width: 100%;
    display: flex;
}

.leftbox {
    width: 150px;
    background: white;
    position: fixed;
    height: 100vh;
    text-align: center;
    padding-top: 100px;
}

.right-box {
    flex: 1;
}

.buttonCss {
    margin-top: 20px;
}

.bg-img {
    width: 800px;
    margin: auto;
    background: white;
    margin-bottom: 2px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
}

.bg-img img {
    width: 100%;
    object-fit: cover;
    margin: auto;
    display: inline-block;
}
</style>
<style>
.new-more tr {
    height: 30px;

}

.new-more table {
    width: 100%;
    border: 1px solid #777777;
}

.new-more td,
.new-more th {
    border: 1px solid #777777;
}

.MsoNormalTable {
    margin-left: unset !important;
}
</style>
