<template>
    <div class="page-wrap">
        <div v-if="showMe">
            <div v-if="sign">
                <qianshu @titleSync="goToView1" :titleSync.sync='data2'></qianshu>
            </div>
            <div v-else>
                <div class="choose-first">
                    <div class="top_change" @click="goBack">
                        <div class="l_where">
                            发起签署<span>/</span>发起合同
                        </div>
                        <div class="r_back" >
                            <i class="iconfont icon-fanhui"></i>
                        </div>
                    </div>
                    <div class="top-nav">
                        <div class="nav_title">基本任务信息</div>
                        <div class="input-group flex">
                            <div class="wrap flex">
                                <div class="label">任务名称：</div>
                                <div class="r-find">
                                    <Input v-model="formValidate.contract_name" placeholder="请输入任务名称" style="width: 240px" />
                                </div>
                            </div>
                            <div class="wrap flex">                            
                                <div class="label">签约截止日期：</div>
                                <div class="r-find">
                                    <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择签约截止日期" style="width: 240px"></DatePicker>
                                </div>
                            </div>
                            
                            <div class="wrap flex">
                                <div class="label">合同标签：</div>
                                <div class="r-find">
                                    <Select v-model="formValidate.label_id" multiple style="width:240px">
                                        <Option v-for="item in ticketList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </div>
                            </div>                    
                        </div>
                        <div class="input-groupA flex">
                            <div class="wrap flex">
                                <div class="label">合同有效日期：</div>
                                <div class="r-find">
                                    <DatePicker @on-change="changeExpired" v-model="expiredVal" type="daterange" placement="bottom-end" placeholder="请选择合同有效日期" style="width: 240px"></DatePicker>
                                </div>
                            </div> 
                            <div class="wrap flex" v-if="$store.state.identity!=2">
                                <Checkbox v-model="formValidate.authentication">合同需要用户认证</Checkbox>
                                <Checkbox v-model="formValidate.smsNotice">短信通知用户</Checkbox>
                            </div>             
                        </div>
                    </div>
                    <div class="have-sel">
                        <div :class="firstBox?'middle-nav':'middle-nav-move'"  >
                            <div class="nav_title">
                                选择模板
                                <Select @on-change="showMsg" v-model="formValidate.template_id" style="width:210px;margin-left: 12px" filterable  clearable @on-clear="clearTemplate" >
                                    <Option v-for="item in tempList" :value="item.id" :key="item.id ">{{ item.template_name }}</Option>
                                </Select>
                            </div>
                            <div class="yinying">(支持格式：docx,doc,png,jpg,pdf)</div>
                            <div class="upload_style_i" v-if="formValidate.template_html_content != ''">
                                <div class="up_del middle-finish">
                                <img src="../../../assets/imgs/123.png" height="58" width="46"/>
                                <div class="finish-success">上传成功</div>
                                <div class="change-status flex-c">
                                    <div class="see flex-c" @click="previewIt">预览</div>
                                    <div class="see flex-c" @click="delTem">删除</div>
                                </div>
                                </div>
                            </div>
                            <div class="upload_style_i" v-if="formValidate.template_html_content == ''">
                                <Upload
                                    :show-upload-list="false"
                                    :headers='headers'
                                    :action="imageAction"
                                    class="up_del flex-c"
                                    :on-format-error="handleFormatError"
                                    :format="['jpg','jpeg','png','doc','docx','pdf']"
                                    :on-success="upload_success">
                                    <div class="up-img">
                                        <img src="../../../assets/imgs/12.png"/>
                                    </div>
                                    <div class="up-btn flex-c">选择文件</div>
                                </Upload>
                            </div>
                        </div>                        
                        <!-- class="middle-r" -->
                        <div :class="seconBox ?'middle-r':'middle-r-move'" >
                            <div class="nav_title">上传附件<span class="nav-tips">仅供查看，无法签署</span></div>
                            <div class="yinying">(支持格式：docx,doc,png,jpg,pdf)</div>
                            <div style="display: flex;margin-top: 12px">
                                <div class="upload_style_i" v-if="accessories_url != ''">
                                <div class="up_del middle-finish">
                                    <img src="../../../assets/imgs/123.png" height="58" width="46"/>
                                    <div class="finish-success">上传成功</div>
                                    <div class="change-status flex-c">
                                    <div class="see flex-c" @click="yulanPic">预览</div>
                                    <div class="see flex-c" @click="delPic">删除</div>
                                    </div>
                                </div>
                                </div>
                                <div class="upload_style_i" v-if="accessories_url == ''">
                                    <Upload
                                        :show-upload-list="false"
                                        :headers='headers'
                                        :name="'img_file'"
                                        :action="pictureAction"
                                        class="up_del flex-c"
                                        :on-format-error="handleFormatError"
                                        :format="['jpg','jpeg','png','doc','docx','pdf']"
                                        :on-success="upload_success_else">
                                        <div class="up-img">
                                        <img src="../../../assets/imgs/12.png"/>
                                        </div>
                                        <div class="up-btn flex-c">选择附件</div>
                                    </Upload>
                                </div>
                                <div class="else_up">
                                    <div class="pic_wrap">
                                        <div class="img_list" v-for="(item,index) in accessoriesList" :key="index">
                                            <img :src="item">
                                            <div class="close" @click="delThis(index)"><Icon type="ios-close-circle-outline" size="30" /></div>
                                        </div>
                                        <div v-if="accessoriesList.length <=4">
                                            <Upload
                                                :show-upload-list="false"
                                                :headers='headers'
                                                :name="'img_file'"
                                                :action="pictureAction"
                                                :on-format-error="handleFormatError"
                                                :max-size="3072"
                                                :on-exceeded-size="errSize"
                                                :format="['jpg','jpeg','png',]"
                                                :on-success="uploadImgSuccess">
                                                <div class="upload_style">
                                                <Icon type="ios-add" size="60"/>
                                                <Button size="small" type="primary">上传图片</Button>
                                                </div>
                                            </Upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="company"  v-if="list1.length > 0">
                            <div class="nav_title">企业</div>
                            <div class="more-del" v-for="(item,index) in list1" :key="index">
                                <div class="input_nav flex">
                                    <div class="wrap flex">
                                        <div class="label">参与方：</div>
                                        <div class="r-find">
                                            <Input v-model="item.participants" placeholder="请输入参与方" style="width: 200px"/>
                                        </div>
                                    </div>
                                    <div class="wrap flex">
                                        <div class="label">企业名称：</div>
                                        <div class="r-find">
                                            <Input v-model="item.prise_name" placeholder="请输入企业名称" style="width: 200px"/>
                                        </div>
                                    </div>
                                    <div class="wrap flex">
                                        <div class="label">经办人姓名：</div>
                                        <div class="r-find">
                                            <Input v-model="item.name" placeholder="请输入经办人姓名" style="width: 200px"/>
                                        </div>
                                    </div>
                                    <div class="wrap flex">
                                        <div class="label">经办人手机号：</div>
                                        <div class="r-find">
                                            <Input v-model="item.phone" type="number" placeholder="请输入经办人手机号" style="width: 200px"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="check-nav">
                                    <div class="wrap flex">
                                        <div class="label">签署要求：</div>
                                        <CheckboxGroup v-model="item.requirement" style="margin-right: 40px">
                                        <Checkbox :label="1">企业公章</Checkbox>
                                        <Checkbox :label="2">经办人签字</Checkbox>
                                        <Checkbox :label="4" v-if="$store.state.identity!=2">短信验证</Checkbox>
                                        </CheckboxGroup>
                                    </div>
                                    <div class="wrap flex" v-if="$store.state.identity!=2">
                                        <div class="label">需支付</div>
                                        <div class="r-find">
                                        <Input v-model="item.money" type="number" placeholder="（选填）" style="width: 120px;margin-left: 12px;margin-right: 12px" />
                                        </div>
                                        <div class="label">元</div>
                                    </div>
                                    <!-- <Button type="primary" ghost @click="toSign(item,index)" v-if="jurisdictionList.indexOf('launch-many-sign') !== -1">设置签名区</Button> -->
                                    <div class="last-btn flex-c" @click="toSign(item,index)" v-if="jurisdictionList.indexOf('launch-many-sign') !== -1">设置签名区{{ item.seal_position.length ?'（已设置)':'' }}</div>
                                    <Tooltip  placement="bottom" v-if="$store.state.identity!=2">
                                        <Icon type="ios-help-circle-outline" size='20' class="icon-cla" />
                                        <div slot="content" style="font-size:12px;white-space: normal;max-width:400px">
                                            <p>如果需要用户多次签署，可设置多个签名区。</p>
                                            <p>若设置签名区，则用户签署位置将会固定为您设置的签名位置。</p>
                                            <p>不设置签名区，用户可以在任何位置签署，但只能签署一次。</p>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div class="check-nav" v-if="jurisdictionList.indexOf('template-components') !== -1">
                                    <div   v-if="enterpriseTemplate" class="wrap flex">
                                        <div  class="newlabel" style="">模板配置：</div>
                                        <CheckboxGroup  v-if='showmoban' v-model="item.template_cg_arr"  style="margin-right: 40px" @on-change='haveMoBan($event,item.template_cg_arr,componentsArr[index])'>                       
                                            <Checkbox v-for="itemx in componentsArr" :label="itemx.id"  :value="itemx.id" :key="itemx.id"                       
                                            v-show="(itemx.isdisabled ? (item.template_cg_arr.indexOf(itemx.id) != -1 ? false :true) : false) ? false :true "
                                            > 
                                                <!-- :disabled="itemx.isdisabled ? (item.template_cg_arr.indexOf(itemx.id) != -1 ? false :true) : false"  --> 
                                                <!-- {{item.template_cg_arr.indexOf(itemx.id) != -1}} -->
                                                {{ itemx.title }}
                                            </Checkbox>                    
                                        </CheckboxGroup>
                                    </div>                 
                                </div>

                                <div class="font flex-c" @click="clearTable('1',index)">
                                    <i class="iconfont icon-shanchu"></i>
                                </div>
                            </div>
                        </div>
                        <div class="company" v-if="list.length > 0">
                            <div class="nav_title">个人</div>
                            <div class="more-del" v-for="(item , index) in list" :key="index">
                                <div class="input_nav flex">
                                    <div class="wrap flex">
                                        <div class="label">参与方：</div>
                                        <div class="r-find">
                                            <Input v-model="item.participants" placeholder="请输入参与方" style="width: 200px"/>
                                        </div>
                                    </div>
                                    <div class="wrap flex">
                                        <div class="label">真实姓名：</div>
                                        <div class="r-find">
                                            <Input v-model="item.name" placeholder="请输入真实姓名" style="width: 200px"/>
                                        </div>
                                    </div>
                                    <div class="wrap flex">
                                        <div class="label">手机号：</div>
                                        <div class="r-find">
                                            <Input v-model="item.phone"  type="number" placeholder="请输入手机号" style="width: 200px"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="check-nav">
                                    <div class="wrap flex">
                                        <div class="label">签署要求：</div>
                                        <CheckboxGroup v-model="item.requirement" style="margin-right: 40px">
                                            <Checkbox :label="2">个人签字</Checkbox>
                                            <Checkbox :label="4" v-if="$store.state.identity!=2">短信验证</Checkbox>
                                        </CheckboxGroup>
                                    </div>
                                    <div class="wrap flex" v-if="$store.state.identity!=2">
                                        <div class="label">需支付</div>
                                        <div class="r-find">
                                        <Input v-model="item.money" type="number" placeholder="（选填）" style="width: 120px;margin-left: 12px;margin-right: 12px" />
                                        </div>
                                        <div class="label">元</div>
                                    </div>
                                    <div class="last-btn flex-c" @click="toSign(item,index)" v-if="jurisdictionList.indexOf('launch-many-sign') !== -1">设置签名区{{ item.seal_position.length ?'（已设置)':'' }}</div>
                                    <Tooltip  placement="bottom" v-if="$store.state.identity!=2">
                                        <Icon type="ios-help-circle-outline" size='20' class="icon-cla" />
                                        <div slot="content" style="font-size:12px;white-space: normal;max-width:400px">
                                            <p>如果需要用户多次签署，可设置多个签名区。</p>
                                            <p>若设置签名区，则用户签署位置将会固定为您设置的签名位置。</p>
                                            <p>不设置签名区，用户可以在任何位置签署，但只能签署一次。</p>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div v-if="jurisdictionList.indexOf('template-components') !== -1">
                                    <div v-if="personalTemplate" class="check-nav">
                                        <div  class="wrap flex"  >
                                            <div  class="newlabel">模板配置：</div>
                                            <CheckboxGroup v-if='showmoban' v-model="item.template_cg_arr"  style="margin-right:40px" @on-change='haveMoBan($event,item.template_cg_arr,componentsArr[index])'>
                                                <Checkbox v-for="itemx in personalArr" :label="itemx.id"  :value="itemx.id" :key="itemx.id"
                                                    v-show="(itemx.isdisabled ? (item.template_cg_arr.indexOf(itemx.id) != -1 ? false :true) : false) ? false :true "
                                                > {{ itemx.title }}</Checkbox>                     
                                            </CheckboxGroup>
                                        </div>                 
                                    </div>
                                </div>
                                <div class="font flex-c" @click="clearTable('2',index)">
                                    <i class="iconfont icon-shanchu"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- three-choose-move -->
                    <div :class="noFileHave?'three-choose':''">
                        <div  class="moreSign flex-b">
                            <div class="select-it flex-c" @click="checkCompany">
                                <div class="icon-pic iconfont icon-qiye"></div>
                                <div class="pic-name">添加企业</div>
                            </div>
                            <div class="select-it flex-c" @click="checkPerson">
                                <div class="icon-pic iconfont icon-tianjiageren"></div>
                                <div class="pic-name">添加个人</div>
                            </div>
                            <div class="select-it flex-c" @click="goAddMrqsz">
                                <div class="icon-pic iconfont icon-tianjiamoren"></div>
                                <div class="pic-name">添加默认签署信息</div>
                            </div>
                        </div>
                    </div>
                    <div :class="showGoLaunch?'':'cannot-launch'">
                        <div class="submit-btn submit-btn-else flex-c" @click="subInfo">
                            <i class="iconfont icon-faqiqianshu1"></i>
                            <span>发起签署</span>
                        </div>
                    </div>
                </div>
                <div class="use-table" v-if="chooseHave">
                    <div class="pop"></div>
                    <div class="pop-inside fixed">
                        <div class="modal-title">添加默认签署者</div>
                        <div class="close-it" @click="chooseHave = false"><Icon type="md-close" size="25" /></div>
                        <div style="padding:0 20px ">
                            <Tabs :value="elsePrams.type"  @on-click="changeTab">
                                <TabPane label="个人" name="1"></TabPane>
                                <TabPane label="企业" name="2"></TabPane>
                            </Tabs>
                            <div style="display: flex;margin-top: 20px;margin-bottom: 20px">
                            <div style="display: flex;align-items: center;margin-right: 20px">
                                <Input v-model="elsePrams.title" style="width: 240px" enter-button placeholder="请输入姓名、电话、企业名称进行搜索"/>
                            </div>
                            <div>
                                <Button type="primary" @click="searchGetDetail">查询</Button>
                            </div>
                            </div>
                            <Table :columns="detailColumns" :loading="loadingTab" :data="detailList" @on-select="selectOnce" @on-select-all="allSelect">
                            <template slot-scope="{ row }" slot="type">
                                <div>{{row.type == '0' ? '个人' : '企业'}}</div>
                            </template>
                            </Table>
                            <br/>
                            <Page :total="elsePrams.total"
                                :current="elsePrams.page"
                                style="text-align:right"
                                show-elevator show-total
                                @on-change="pageChange"
                                :page-size="elsePrams.limit"/>   
                        </div>
                        <div  class=" footer-cla">
                            
                        </div>
                        <div class="choose-it flex-c" @click="submitList">确认</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <Preview ref="preview" @titleSync="seeBack"></Preview>
        </div>
    </div>
</template>

<script>
import { getCookies } from '@/utils/helper'
import config from '@/config/index'
import Preview from '../../../components/preview/index'
import qianshu from '../../../components/qianshu/qianshu'
import {info,templist,deletetmp, edittempupdate, delEnclosure, contractadd, signatoryList, searchList} from '../../../api/interFace'
export default {
    name: "index",
    components:{
        Preview,
        qianshu
    },
    data(){
        return{
            sign:false,
            loadingTab:false,
            enterpriseTemplate:false,
            personalTemplate:false,
            showmoban:false,
            showfujian:true, //附件样式
            noFileHave:true, 
            firstBox:true,
            seconBox:true,
            countNum: 0,
            showGoLaunch:false, //发起签署样式
            list:[],
            list1:[],
            ticketList:[],
            componentsArr:[],
            needMoban:[],
            zhongzhuanCompts: [],
            personalArr:[],
            data2:{},
            showMe:true,
            elsePrams: {
                limit: 5,
                page: 1,
                total: 0,
                title: '',
                type: '1',
                status: 1
            },
            detailColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '#',
                    key: 'id',
                    width: 80,
                    align: 'center'
                },
                {
                    title: '参与方',
                    key: 'participants',
                    align: 'center'
                },
                {
                    title: '姓名',
                    key: 'name',
                    align: 'center'
                },
                {
                    title: '电话',
                    key: 'phone',
                    align: 'center'
                },
                {
                    title: '所属主体',
                    slot: 'type',
                    align: 'center'
                },
                {
                    title: '企业名称',
                    key: 'prise_name',
                    align: 'center'
                },
            ],
            detailList: [],
            formValidate: {
                authentication: false,
                smsNotice: false,
                template_html_content: '',
                template_html: '',
                template_id: '',
                contract_name: '',
                contract_content: '',
                contract_annex: [],
                contract_imgs: [],
                components_arr:[],
                part: [],
                start_time: '',
                end_time: '',
                start_expired: '', //有效期开始时间
                end_expired: '', //有效期结束时间
                document_type: '',
                upload_type: 2,
                label_id:''
            },
            components_arr:[],
            tempList:[],
            accessories_file:'',
            accessories_url:'',
            chooseHave:false,
            accessoriesList:[],
            accessories_img:[],
            requirementElse:[],
            imageAction:config.apiUrl + '/adminapi/common/uploads',
            pictureAction:config.apiUrl + '/adminapi/contract/upload_enclosure',
            headers:{'Authori-zation' : getCookies('token')},
            modelSelect:'',
            showType:'',
            creatList: [],
            jurisdictionList:[],
            timeVal:[],
            expiredVal:[],  //有效期数组
            clickNum: 0,
            submiting:false,
            oldValue: "",
        }
    },
    created() {
        this.$store.commit('changePollingSpin', false) //外部loading关闭
        this.getTem()
        this.getDetail()
        this.getTickets()
        this.jurisdictionList = this.$store.state.auth
    },
    watch: {
        get_template_id: {
            handler: function() {
                if(this.formValidate.template_id || this.formValidate.template_html_content){
                    // console.log('this.formValidate.templasdasdate_id',this.formValidate.template_id)                    
                    // if(this.countNum == 1){
                        this.noFileHave = false
                        this.showfujian = false
                    // }                    
                }else{
                    // if(this.countNum == 1){
                        this.noFileHave = true
                        this.showfujian = true
                    // }  
                    
                }
            },
        },        
    },
    computed:{
        get_template_id: function() {
            return this.formValidate.template_id
        }       
    },
    methods:{    
        goAddMrqsz(){
            this.chooseHave = true
            console.log('list',this.list)
            console.log('list1',this.list1)
        }, 
        previewIt(){
            this.showMe = false
            console.log('this.formValidate',this.formValidate)
            this.$nextTick(()=>{
                this.$refs.preview.openIt(this.formValidate)
            })
        },
        seeBack(){
        this.showMe = true
        },
        toSign(row,index){
            if(this.formValidate.template_html_content == '') return this.$Message.warning('请先选择文件')
            if (!row.participants) return this.$Message.warning('请填写参与方')
            if (!row.name) return this.$Message.warning('请输入姓名')
            if (!row.phone) return this.$Message.warning('请输入手机号')
            if (!row.requirement || row.requirement.length == 0) return this.$Message.warning('请选择签署要求')
            if (row.money_check == 1 && (!row.money || isNaN(row.money))) return this.$Message.warning('请输入金额');

            let components_arr =[]
            if (typeof row.template_cg_arr != "undefined") {
                row.template_cg_arr.forEach( item1 =>{
                    this.formValidate.components_arr.forEach( item2 =>{
                        if(item1==item2.id){
                            components_arr.push(item2)
                        }
                    })
                    if(item1==[]){
                        components_arr=[]
                    }
                })
            }

            this.new_content = this.formValidate.template_html_content
            this.data2.template_html_content = this.new_content
            this.data2.partType = this.clickNum
            this.data2.image_list = this.formValidate.contract_imgs
            this.data2.type = 1
            this.data2.arr = row
            this.data2.components_arr = components_arr
            this.sign = true
            this.data2.arr.sealIndex = index
        },
        goToView1(e) {
            this.sign = false
            if (typeof  e.type == "undefined") {
                return
            }
            if(e.type === 1){
                this.list1[e.index].seal_position = e.seal_position
            }else {
                this.list[e.index].seal_position = e.seal_position
            }
            // e.seal_position.forEach(item=>{
            //   this.arr.push(item)
            // })//
        },
        changeTime(e){
            this.formValidate.start_time = e[0]
            this.formValidate.end_time =  e[1]
        }, 
        changeExpired(e){
            this.formValidate.start_expired = e[0]
            this.formValidate.end_expired =  e[1]
        }, 
        showMsg(e){
            let a = false
            if(this.list.length>0){
                this.list.forEach(item=>{
                    if(item.seal_position.length>0){
                        a = true
                    }
                })
            }
            if(this.list1.length>0){
                this.list1.forEach(item=>{
                    if(item.seal_position.length>0){
                        a = true
                    }
                })
            }
            if(a){
                this.$Modal.confirm({
                    title: '切换文件通知',
                    content: '请问您是否确认切换文件，该操作可能导致您之前设置的签名区清空',
                    onOk: () => {
                        this.list.forEach(item=>{
                            if(item.seal_position.length>0){
                               item.seal_position=[]
                            }
                        })
                        this.list1.forEach(item=>{
                            if(item.seal_position.length>0){
                               item.seal_position=[]
                            }
                        })
                        this.chooseMsg(e) 

                    },
                    onCancel: () => {
                        this.formValidate.template_id = this.oldValue
                        return
                    }
                });
            }else {
                this.chooseMsg(e) 
            }               
        },        
        launchGoDelConsumer(){
            if(this.list.length > 0){
                this.list.forEach((item,index)=>{
                    this.clearTable('2',index)
                })
            }
            if(this.list1.length > 0){
                this.list1.forEach((item,index)=>{
                    this.clearTable('1',index)
                })
            }
            this.isHaveConsumer()//发起签署样式控制
        },

        //选择模板
        chooseMsg(e){
            this.oldValue = e
            this.personalTemplate=false
            this.enterpriseTemplate=false
            if(!e){
                return
            }
            const msg = this.$Message.loading({
                content: '文件较大正上传中，请等待...',
                duration: 0
            });
            info({id:e}).then(async res => {
                this.formValidate.document_type = res.data.document_type
                this.formValidate.upload_type = res.data.upload_type
                this.formValidate.template_html_content = res.data.template_html_content
                this.formValidate.contract_content = res.data.template_content
                this.formValidate.contract_imgs = res.data.contract_imgs
                this.formValidate.contract_pdf = res.data.path

                this.formValidate.components_arr=res.data.components_arr
                this.componentsArr = res.data.components_arr
                this.personalArr = res.data.components_arr
                 
                //给所有复选框附加样式
                this.needMoban = []
                if(this.componentsArr.length > 0){
                    this.componentsArr.forEach(item=>{
                        item.isdisabled = false
                    })
                }        
                if(this.personalArr.length > 0){
                    this.personalArr.forEach(item=>{
                        item.isdisabled = false
                    })
                }   
                
                //切换后 之前所有在该条件下勾选状态变空 
                this.list1.forEach(item=>{
                    item.template_cg_arr = []
                })
                this.list.forEach(item=>{
                    item.template_cg_arr = []
                }) 
                this.haveMRGx()
                // path
                //判断是否为空数组 如果是 模板配置不显示
                if(this.formValidate.components_arr.length != 0){
                    this.personalTemplate=true
                    this.enterpriseTemplate=true
                }
                this.showmoban = true    
                setTimeout(msg, 100);
                //样式控制
                this.seconBox = false
                this.firstBox = false
                this.isHaveConsumer()//发起签署样式控制
            })
            
        },
        getTickets(){
            searchList().then(res=>{
                this.ticketList = res.data || []
            }).catch(res=>{
                console.log(res)
            })
        },
        yulanPic(){
            window.open(this.accessories_file)
        },
        delPic() {
            delEnclosure({img_url:this.accessories_url}).then(res=>{
                this.$Message.success(res.msg);
                this.accessories_file = ''
                this.accessories_url = ''
            })
        },
        changeTab(e){
            this.elsePrams.type = e
            this.getDetail()
        },
        selectOnce(e){
            this.creatList = []
            e.forEach(item=>{
                if(item.requirement === 1 ) {
                    item.requirement = [1]
                }else if(item.requirement === 2 ) {
                    item.requirement = [2]
                }else if(item.requirement === 3){
                    item.requirement = [1, 2]
                }else if(item.requirement === 4 ) {
                    item.requirement = [4]
                }else if(item.requirement === 5 ) {
                    item.requirement = [1, 4]
                }else if(item.requirement === 6 ) {
                    item.requirement = [2, 4]
                }else if(item.requirement === 7 ) {
                    item.requirement = [1, 2, 4]
                }
                item.money_check = 0
                item.seal_position = []
                item.template_cg_id = []
                this.creatList.push(item)
            })
        },
        allSelect(e){
            e.forEach(item=>{
                if(item.requirement === 1 ) {
                    item.requirement = [1]
                }else if(item.requirement === 2 ) {
                    item.requirement = [2]
                }else if(item.requirement === 3){
                    item.requirement = [1, 2]
                }else if(item.requirement === 4 ) {
                    item.requirement = [4]
                }else if(item.requirement === 5 ) {
                    item.requirement = [1, 4]
                }else if(item.requirement === 6 ) {
                    item.requirement = [2, 4]
                }else if(item.requirement === 7 ) {
                    item.requirement = [1, 2, 4]
                }
                item.money_check = 0
                item.seal_position = []
                item.template_cg_id = []
            })
            this.creatList = e
        },
        searchGetDetail(){
            this.elsePrams.page = 1
            this.getDetail()
        },
        getDetail(){
            this.loadingTab = true
            signatoryList(this.elsePrams).then(res => {
                this.loadingTab = false
                this.detailList = res.data.data || []
                this.elsePrams.total = res.data.total
            }).catch(res => {
                this.loadingTab = false
                console.log(res)
            })
        },
        pageChange(index) {
            this.elsePrams.page = index
            this.getDetail()
        },
        submitList(){
            if(this.list.length>0){
                this.list.forEach(item=>{      
                    item.template_cg_arr = []
                })
                //5.7新增 当先个人再添加默认签署者 会导致模板组件丢失     样式isdisabled要设置false
                this.personalArr.forEach(item=>{
                    item.isdisabled = false
                })
                //5.7新增结束
            }
            if(this.list1.length>0){
                this.list1.forEach(item=>{
                    item.template_cg_arr = []
                })
                //5.7新增 当先个人再添加默认签署者 会导致模板组件丢失     样式isdisabled要设置false
                this.componentsArr.forEach(item=>{
                    item.isdisabled = false
                })
                //5.7新增结束
            }              
            this.creatList.forEach((item,index)=>{
                this.$set(this.creatList[index] ,'template_cg_arr',[])
                // item.template_cg_arr = []
            })
            if(this.elsePrams.type == 1){
                if(this.creatList.length>0){
                    this.creatList.forEach(item=>{
                        this.list.push(item)
                    })
                }
                // this.list = this.creatList
            }else {
                 if(this.creatList.length>0){
                    this.creatList.forEach(item=>{
                        this.list1.push(item)
                    })
                }
                // this.list1 = this.creatList
            }   
            this.needMoban = [] 
            this.haveMRGx()//默认签署者勾选模板组件   模糊名称              
            // this.haveFuzzyName()   //           
            this.chooseHave = false
            this.isHaveConsumer()   //发起签署样式
            console.log('endpersonarr',this.personalArr)    
            console.log('endcomponentsArr',this.componentsArr)    
        },
        
        haveMRGx(){            
            let allType = []
            allType = []
            if(this.list.length > 0){
                this.list.forEach((item) =>{
                    if(item.assembly_id == undefined){
                        item.assembly_id = ''
                    }
                    if(item.assembly_id !==''){
                        item.assembly_id.forEach(itemass=>{
                            this.personalArr.forEach(itemper=>{
                                if(itemass.title == itemper.title && itemass.type == itemper.type ){
                                    let cango = true
                                    allType.forEach(X => {
                                        if(X.title == itemass.title && X.type == itemass.type){
                                            cango = false
                                        }
                                    })                                
                                    if(cango){
                                        item.template_cg_arr.push(itemper.id)
                                        let a = []
                                        a.push(itemper.id)
                                        this.haveMoBan(a)                                    
                                    }
                                }
                            })
                            allType.push(itemass)
                        })
                    }                    
                })
            }
            if(this.list1.length>0){
                this.list1.forEach((item) =>{
                    if(item.assembly_id == undefined){
                        item.assembly_id = ''
                    }
                    if(item.assembly_id !==''){                        
                        item.assembly_id.forEach(itemass=>{
                            this.componentsArr.forEach(itemper=>{
                                if(itemass.title == itemper.title && itemass.type == itemper.type ){
                                    let cango = true
                                    allType.forEach(X => {
                                        if(X.title == itemass.title && X.type == itemass.type){
                                            cango = false
                                        }
                                    })                                
                                    if(cango){
                                        item.template_cg_arr.push(itemper.id)
                                        let a = []
                                        a.push(itemper.id)
                                        this.haveMoBan(a)                                    
                                    }
                                }
                            })
                            allType.push(itemass)
                        })
                    }
                    
                })
            }
        },

        haveFuzzyName(){
            if(this.list.length > 0){
                let allAssembly_id = []
                this.list.forEach(item=>{
                    if(item.assembly_id !==''){
                        item.assembly_id.forEach(item=>{
                            allAssembly_id.push(item.title)
                        })
                    }
                })
                allAssembly_id = [...new Set(allAssembly_id)];
                this.list.forEach(item=>{
                    let hasId = []
                    let a = []
                    if(item.assembly_id==''){
                        a = []
                    }else{
                        item.assembly_id.forEach(item=>{
                            a.push(item.title)
                        })
                    }
                    let b = []
                    b = this.repetition(allAssembly_id,a)

                    this.personalArr.forEach(item1=>{                        
                        if(b.indexOf(item1.title) !==-1){
                            let type = ''
                            item.assembly_id.forEach(items=>{
                                if(items.title == item1.title){
                                    type =items.type
                                }
                            })
                            if(type == item1.type){
                                hasId.push(item1.id)
                                b.splice(b.indexOf(item1.title),1)
                                allAssembly_id.splice(allAssembly_id.indexOf(item1.title),1)
                            }
                        }
                    })
                    if(hasId.length>0){
                        hasId.forEach(itemx=>{
                            item.template_cg_arr.push(itemx)
                        })
                    }                    
                    this.haveMoBan(hasId)
                })
            }
            //企业
            if(this.list1.length > 0){
                let allAssembly_idX = []
                this.list1.forEach(item=>{
                    if(item.assembly_id !==''){
                        item.assembly_id.forEach(item=>{
                            allAssembly_idX.push(item.title)
                        })
                    }
                })
                allAssembly_idX = [...new Set(allAssembly_idX)];
                this.list1.forEach(item=>{
                    let hasId = []
                    let a = []
                    if(item.assembly_id==''){
                        a = []
                    }else{
                        item.assembly_id.forEach(item=>{
                            a.push(item.title)
                        })
                    }
                    // let chongfu = []
                    // chongfu = this.getChongfu(a)
                    // chongfu.forEach(item => {
                    //     a.push(item)
                    // })
                    let b = []
                    b = this.repetition(allAssembly_idX,a)                   
                    this.componentsArr.forEach(item1=>{
                        if(b.indexOf(item1.title) !==-1){  
                            let type = ''
                            item.assembly_id.forEach(items=>{
                                if(items.title == item1.title){
                                    type =items.type
                                }
                            })
                            if(type == item1.type){
                                hasId.push(item1.id)
                                b.splice(b.indexOf(item1.title),1)
                                allAssembly_idX.splice(allAssembly_idX.indexOf(item1.title),1)
                            }
                        }
                    })
                    if(hasId.length>0){
                        hasId.forEach(itemx=>{
                            item.template_cg_arr.push(itemx)
                        })
                    }                    
                    this.haveMoBan(hasId)
                })
            }
        },
        getChongfu(arr){
            var Arr = [];
            for(let i=0; i<arr.length; i++ ){
                for(let j=i+1; j<arr.length; j++){
                    if(arr[i]===arr[j] && Arr.indexOf(arr[j])===-1){
                        Arr.push(arr[i]);
                    }
                }
            }
            return Arr;
        },
        //判断是否有个人企业或者默认签署者  控制发起签署样式
        isHaveConsumer(){
            if(this.list1.length == 0 &&this.list.length == 0 ){
                this.showGoLaunch = false
            }else{
                this.showGoLaunch = true                               
            }            
        },
        repetition(arr,brr){
            var newArr=[];
            for (var i = 0 ; i < arr.length ; i++) {
					//循环第二个数组
					for (var j = 0 ; j < brr.length ; j++) {
						if((arr[i]==brr[j])&&newArr.indexOf(arr[i])==-1){
							newArr.push(arr[i]);
						}
					}
				}
				//返回新数组
				return newArr;
        },
        checkCompany() {
            // if( this.enterpriseTemplate){
            //    this.enterpriseTemplate=false
            // }else{
            //    this.enterpriseTemplate=true
            // }
            this.list1.push({
                prise_name: '',
                type: 1,
                money_check: 0,
                part_type: 0,
                seal_position:[],
                setNow: this.timer,
                template_cg_id:[],
                template_cg_arr:[],
                positionX: '',//个人章X坐标
                positionY: '',//个人章y坐标
                positionfirmX: '',//企业章X坐标
                positionfirmY: ''//企业章y坐标
            })
            this.isHaveConsumer() //发起签署样式
        },
        checkPerson() {
            //   if( this.personalTemplate){
            //    this.personalTemplate=true
            // }else{
            //    this.personalTemplate=false
            // }
            this.list.push({
                type: 0,
                money_check: 0,
                seal_position:[],
                template_cg_id:[],
                template_cg_arr:[],
                part_type: 0,
                positionX: '',
                positionY: '',
                setNow: this.timer
            })
            console.log('this.list',this.list)
            this.isHaveConsumer() //发起签署样式
        },
        clearTable(type,index){      
            if(type === '1') {
                if( this.list1[index].template_cg_arr.length > 0){
                    this.componentsArr.forEach( item=>{
                        this.list1[index].template_cg_arr.forEach(item2=>{
                            if(item.id == item2){
                                item.isdisabled = false
                                let a = this.needMoban.indexOf(item2)
                                this.needMoban.splice(a,1)  
                            }
                        })   
                    })       
                }     
                this.list1.splice(index, 1);
            }else {
                if( this.list[index].template_cg_arr.length > 0){
                    this.componentsArr.forEach( item=>{
                        this.list[index].template_cg_arr.forEach(item2=>{
                            if(item.id == item2){
                                item.isdisabled = false
                                let a = this.needMoban.indexOf(item2)
                                this.needMoban.splice(a,1)  
                            }
                        })   
                    })       
                }  
                this.list.splice(index, 1);
            }        
            this.isHaveConsumer()   //发起签署样式 
        },
        clearTemplate(){
            //模板样式删除
            this.showmoban=false
            this.personalTemplate = false
            this.enterpriseTemplate = false
            this.formValidate.template_id = ''
            this.formValidate.template_html_content = ''
            this.formValidate.contract_content = ''
            this.formValidate.contract_annex.length = 0
            this.formValidate.contract_pdf = ''
            this.needMoban = []
            this.formValidate.contract_imgs = []
            let a = false
            if(this.list.length>0){
                this.list.forEach(item=>{
                    if(item.seal_position.length>0){
                        item.seal_position=[]
                        a = true
                    }
                })
            }
            if(this.list1.length>0){
                this.list1.forEach(item=>{
                    if(item.seal_position.length>0){
                        item.seal_position=[]
                        a = true
                    }
                })
            }
            if(a){
                this.$Message.warning('签名区已清空，请重新选择文件后重设')
            }
            
            this.showGoLaunch = false
            this.isAttachment() //判断是否有附件
            // this.launchGoDelConsumer() //清空文件 后个人企业默认签署者清空 样式关闭
            
        },
        isAttachment(){
            if(this.accessories_url || this.accessoriesList.length > 0){
                console.log('有附件')
                this.seconBox = false
                this.firstBox = false
            }else {
                console.log('无附件')
                this.seconBox = true
                this.firstBox = true
            }
        },
        delTem() {
            let a = false
            if(this.list.length>0){
                this.list.forEach(item=>{
                    if(item.seal_position.length>0){
                        a = true
                    }
                })
            }
            if(this.list1.length>0){
                this.list1.forEach(item=>{
                    if(item.seal_position.length>0){
                        a = true
                    }
                })
            }
            if(a){
                this.$Modal.confirm({
                    title: '删除文件通知',
                    content: '请问您是否确认删除文件，该操作可能导致您之前设置的签名区清空',
                    onOk: () => {
                        this.list.forEach(item=>{
                            if(item.seal_position.length>0){
                               item.seal_position=[]
                            }
                        })
                        this.list1.forEach(item=>{
                            if(item.seal_position.length>0){
                               item.seal_position=[]
                            }
                        })
                        this.deleteWD()
                    },
                    onCancel: () => {
                        this.oldValue = ''
                        return
                    }
                });
            }else {
                this.deleteWD()
            }

            this.showGoLaunch = false   
            this.isAttachment() //判断是否有附件         
            // this.launchGoDelConsumer() //清空文件 后个人企业默认签署者清空 样式关闭
        },
        deleteWD() {            
            console.log('template_id:', this.formValidate.template_id)
            if (this.formValidate.template_id == '' || this.formValidate.template_id == undefined) {
                deletetmp({contract_path: this.formValidate.contract_imgs}).then(res => {
                    this.$Message.success(res.msg)
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            }
            this.formValidate.contract_imgs = []
            this.clearTemplate();
        },
        getTem(){
            templist({status:'all'}).then(async res => {
                this.tempList = res.data.data
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        upload_success_else (e){
            this.accessories_file = e.data.img_url
            this.accessories_url = e.data.src
            this.$Message.destroy()
        },
        uploadImgSuccess (e) {
            this.accessoriesList.push(e.data.img_url)
            this.accessories_img.push(e.data.src)
            this.$Message.destroy()
            this.$Message.success('上传成功');
            
        },
        goBack(){
            this.$emit('goBack')
        },
        // 文件类型上传失败
        handleFormatError() {
            this.$Message.warning('暂不支持上传此类型文件')
        },
        // 判断类型上传文件
        upload_success(e) {
            const msg = this.$Message.loading({
                content: '文件上传中，请等待...',
                duration: 0
            });
            this.formValidate.contract_content = e.data.src         
            //获取合同 HTML
            edittempupdate({path: e.data.src}).then(async res => {                
                this.formValidate.document_type = res.data.document_type
                this.formValidate.upload_type = res.data.upload_type
                // this.formValidate.template_html = res.data.path
                this.formValidate.template_html_content = res.data.html
                this.formValidate.contract_imgs = res.data.imgs
                this.formValidate.contract_pdf = res.data.path
                this.$Message.success(e.msg)
                
                this.noFileHave = false //控制样式
                this.showfujian = false //控制样式                
                //样式控制
                this.seconBox = false
                this.firstBox = false
                if(this.list.length>0||this.list1.length>0){
                    this.showGoLaunch = true
                }

                setTimeout(msg, 100);
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        haveMoBan(e){
            let a = 0 //统计所有选中长度
            let b = [] //统计所有选中的id
            this.list.forEach(item=>{
                a += item.template_cg_arr.length
                if(item.template_cg_arr.length>0){
                    item.template_cg_arr.forEach(item1=>{
                        b.push(item1)
                    })
                }
            })
            this.list1.forEach(item=>{
                a += item.template_cg_arr.length
                if(item.template_cg_arr.length>0){
                    item.template_cg_arr.forEach(item1=>{
                        b.push(item1)
                    })
                }
            })
            //判断是勾选还是取消
            if(this.needMoban.length - a == 1){
                //取消  找出取消的id 赋给其他数组isdisabled值
                const _arr1 = this.needMoban.filter(item1 => !b.includes(item1));
                const _arr2 = b.filter(item2 => !this.needMoban.includes(item2));
                const _arr = _arr1.concat(_arr2)
                this.componentsArr.forEach(item=>{
                    if(item.id == _arr[0]){
                        item.isdisabled = false  
                    }
                })
                this.personalArr.forEach(item=>{
                    if(item.id == _arr[0]){
                        item.isdisabled = false  
                    }
                })
                let c = this.needMoban.indexOf(_arr[0])
                this.needMoban.splice(c,1)   
            }else{
                //勾选 则其他数组isdisabled 消失
                this.componentsArr.forEach(item=>{
                    if( e.indexOf(item.id) != -1){      
                        item.isdisabled = true     
                        this.needMoban.push(item.id )
                    }
                })
                this.personalArr.forEach(item=>{
                    if( e.indexOf(item.id) != -1){      
                        item.isdisabled = true       
                    }
                })          
                this.needMoban = [...new Set(this.needMoban)]; //利用了Set结构不能接收重复数据的特点
            }
        },
        errSize(){
            this.$Message.error('上传图片不得超过3M');
        },
        delThis(index){
            let val = this.accessories_img[index]
            delEnclosure({img_url:val}).then(res=>{
                this.$Message.success(res.msg);
                this.accessoriesList.splice(index,1)
                this.accessories_img.splice(index,1)
            })
        },
        //zheng
        phoneFun(phones){
            var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (!myreg.test(phones)) {
                return true;
            } else {
                // console.log(\'手机号格式正确\')
                return false;
            }
        },
        ishasGo(){
            let isPary = false
            console.log('this.list1',this.list1)
            if(this.list1.length > 0){
                this.list1.forEach(item=>{
                    let qyqsyq = [] //企业签名要求
                    let qyqmq = []  //签名区所有数组的类型
                    if(item.requirement){
                        if(item.requirement.length > 0){
                            item.requirement.forEach(item1=>{
                                qyqsyq.push(item1)
                            })
                        } 
                    }
                                      
                    if(item.seal_position.length>0){
                        qyqmq = []
                        item.seal_position.forEach(itemy=>{
                            qyqmq.push(itemy.type)
                        })      
                        qyqsyq.forEach(itemX=>{
                            if( itemX == 2 ){ 
                                if( qyqmq.indexOf(0) == -1){
                                    isPary = true
                                }
                            }
                            if( itemX == 1){   
                                if( qyqmq.indexOf(1) == -1){
                                    isPary = true
                                }
                            }
                        })    
                        //若用户勾选了企业公章和个人签字且设置签名区  若只设置了企业公章区域则错
                        if(qyqsyq.indexOf(1) !== -1&&qyqsyq.indexOf(2) !==-1){ 
                            if(qyqmq.indexOf(0) == -1 || qyqsyq.indexOf(1) ==-1){
                                isPary = true
                            }
                        }           
                    }
                    
                })
            }
            return  isPary
        },
        subInfo() {    
            // console.log('this.formValidate.',this.formValidate)
            // console.log('this.list1',this.list1)  
            // console.log('this.list',this.list) 
            if(this.ishasGo()) return this.$Message.warning('设置签名区与签署要求不匹配,请添加个人印章或添加企业印章')    
            
            if (this.formValidate.contract_name == '') return this.$Message.warning('请填写合同名称')
            if (this.formValidate.start_time == '' || this.formValidate.start_time == 'NaN-NaN-NaN') return this.$Message.warning('请选择签约截止日期')
            if (this.formValidate.end_time == '' || this.formValidate.end_time == 'NaN-NaN-NaN') return this.$Message.warning('请选择签约截止日期')
            // if (this.formValidate.start_expired == '' || this.formValidate.start_expired == 'NaN-NaN-NaN') return this.$Message.warning('请选择有效期时间')
            // if (this.formValidate.end_expired == '' || this.formValidate.end_expired == 'NaN-NaN-NaN') return this.$Message.warning('请选择有效期时间')
            if(this.formValidate.end_time && this.formValidate.end_expired && (new Date(this.formValidate.end_time).getTime() * 1  > new Date(this.formValidate.end_expired).getTime()*1) ){
                this.$Message.warning('合同有效日期结束时间必须大于签约截止日期结束时间') 
                return
            }
            if (this.formValidate.contract_content == '') return this.$Message.warning('请上传文件')
            this.formValidate.part = [];
            let error_msg = "";          
            if (this.list.length > 0) {
                //验证手机号码
                let a  = false
                this.list.forEach(item=>{
                    if(item.phone){
                        if(item.phone.length != 11 || item.phone.indexOf('.') != -1){
                            a = true
                        }                     
                    }
                })
                if(a) return this.$Message.warning('请输入正确的手机号')

                for ( let i in this.list ) {          
                    let item = this.list[i]
                    if (item.money>0) {
                        item.money_check = 1
                    }
                    if (!item.participants) {error_msg = '请填写参与方'; break; }
                    if (!item.name) {error_msg = "请输入姓名"; break;}
                    if (!item.phone || isNaN(item.phone)){ error_msg = "请输入正确的手机号"; break;}                    
                    if (!item.requirement || item.requirement.length == 0) { error_msg = "请选择签署要求"; break;}
                    if (typeof item.template_cg_arr != 'undefined' && item.template_cg_arr.length > 0) {
                        item.template_cg_id = []
                        item.template_cg_arr.forEach( item1 =>{
                            this.formValidate.components_arr.forEach( item2 =>{
                                if(item1==item2.id){
                                    item.template_cg_id.push(item2)
                                }
                            })
                            if(item1 == []){
                                item.template_cg_id=[]
                            }
                        })
                    }
                    //若用户填写错的手机号 设置好签名区域再回来修改 签名区sealposition里手机号也要修改
                    if(item.seal_position.length > 0){
                        item.seal_position.forEach( xgphone => {
                            xgphone.phone = item.phone
                        })
                    }
                    this.formValidate.part.push(item)
                }
            }
            if (this.list1.length > 0) {
                //验证手机号码
                let a  = false
                this.list1.forEach(item=>{
                    if(item.phone){
                        if(item.phone.length != 11 || item.phone.indexOf('.') != -1){
                            a = true
                        }                     
                    }          
                })
                if(a) return this.$Message.warning('请输入正确的手机号')                
                for(let j in this.list1) {
                    let item = this.list1[j]
                    if (item.money>0) {
                        item.money_check = 1
                    }
                    if (!item.participants){ error_msg = "请填写参与方"; break;}
                    if (!item.prise_name){ error_msg = "请填写企业名称"; break;}
                    if (!item.name) {error_msg = "请输入姓名"; break;}
                    if (!item.phone){ error_msg = "请输入手机号"; break;}
                    if (!item.requirement || item.requirement.length == 0){ error_msg = "请选择签署要求"; break;}
                    
                    if (typeof item.template_cg_arr != 'undefined' &&  item.template_cg_arr.length>0) {
                        item.template_cg_id = []
                        item.template_cg_arr.forEach( item1 =>{
                            this.formValidate.components_arr.forEach( item2 =>{
                                if(item1==item2.id){
                                item.template_cg_id.push(item2)
                                }
                            })
                            if(item1==[]){
                                item.template_cg_id=[]
                            }
                        })
                    }
                    //若用户填写错的手机号 设置好签名区域再回来修改 签名区sealposition里手机号也要修改
                    if(item.seal_position.length > 0){
                        item.seal_position.forEach( xgphone => {
                            xgphone.phone = item.phone
                        })
                    }
                    this.formValidate.part.push(item)
                }
            }
            if (error_msg != "") {
                this.$Message.warning(error_msg);
                return;
            }
            if (this.formValidate.part.length == 0) {
                this.$Message.warning('请添加参与者');
                return;
            }
            this.formValidate.accessories_img = String(this.accessories_img)
            this.formValidate.accessories_url = this. accessories_url
            if (this.submiting) {
                return
            }
            this.submiting = true
            this.$Message.loading({
                content: '发起中',
                duration: 0
            });
            console.log("this.formValidate",this.formValidate)
            contractadd(this.formValidate).then(async res => {
                this.submiting = false
                this.showGoLaunch = false
                this.noFileHave = true
                this.$Message.destroy()
                this.$Message.success(res.msg)
                this.restetData();
            }).catch(res => {
                console.log('错误res',res)
                this.submiting = false
                this.$Message.destroy()
                this.$Message.error(res.msg)
            })
        },
        restetData() {
            this.formValidate = {
                label_id:"",
                template_html_content:"",
                template_html:"",
                template_id:"",
                contract_name:"",
                contract_content:"",
                contract_annex:[],
                part:[],
                start_time:"",
                end_time:"",
                template_cg_id:[],
                template_cg_arr:[],
                document_type:"",
                upload_type:2,
                authentication:false,
                accessories_id:0
            }
            this.personalArr = []
            this.componentsArr = []
            this.enterpriseTemplate = false
            this.personalTemplate = false
            this.list = [];
            this.list1 = [];
            this.expiredVal = []
            this.timeVal = []
            this.accessoriesList = []
            this.accessories_img = []
            this.accessories_url = ''
            this.accessories_file = ''
            console.log('this.enterpriseTemplate', this.enterpriseTemplate)
        },
    }
}
</script>

<style scoped lang="scss">
@keyframes middle-nav
	{
    from {width: 100%;}
	to {width: 25%}
}
@-webkit-keyframes middle-nav {
    from {width: 100%;}
	to {width: 25%}
}

@keyframes middle-r
	{
    from {width: 304px}
	to {width: 73%}
}
@-webkit-keyframes middle-r {
    from {width: 304px;}
	to {width: 73%}
}
@keyframes three-choose
	{
    from {width: 25%;}
	to {width: 100%}
}
@-webkit-keyframes three-choose {
    from {width: 25%;}
	to {width: 100%}
}
.cannot-launch {
    display: none;
}
.newlabel {
    font-size: 14px;
    width: 80px;
    font-weight: bold;
    color: #1B2742;
    word-break:keep-all; white-space:nowrap;
}
.footer-cla {
    margin-top: 24px;
    border-top:1px solid #e8eaec;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 12px 32px;  
}
.modal-title {
  font-size: 16px;
  color: #17233d;
  border-bottom:1px solid #e8eaec;
  // margin-bottom: 25px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left:16px ;
  margin-bottom: 8px;
}
::v-deep .ivu-tooltip-inner {
    max-width: 400px!important;
}
.icon-cla {
    margin-left:12px;
    margin-right:12px;
    margin-top:6px;
    cursor: pointer;
}

.yinying {
    margin-top: 8px;
    opacity: 0.6;
    user-select: none;
}
.page-wrap {
    .top_change {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        margin-bottom: 20px;
        cursor: pointer;
        box-sizing: border-box;
        height: 60px;
        .l_where {
            font-size: 18px;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }
        .r_back {
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }
    }
    .first-in {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        .check-item {
            width: 280px;
            height: 340px;
            background: #FFFFFF;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
            border: 2px solid #fff;
            box-sizing: border-box;
            &:hover {
                border: 2px solid #4877E8;
            }
            .iconfont {
                font-size: 80px;
                color: #6D92ED;
            }
            .word {
                margin-top: 12px;
                font-size: 28px;
                color: #545F78;
            }
        }
    }
    .choose-first {
        .top-nav {
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px 0 20px;
            box-sizing: border-box;
            .input-group {
                margin-top: 20px;
                flex-wrap: wrap;
                .wrap {
                    margin-right: 20px;
                    margin-bottom: 8px;
                }
                .label {
                    font-size: 14px;
                    color: #545F78;
                }
            }
            .input-groupA {
                margin-top: 8px;
                flex-wrap: wrap;
                .wrap {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
                .label {
                    font-size: 14px;
                    color: #545F78;
                }
            }
        }
        .have-sel {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
        .middle-nav {
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
        }
        .middle-nav-move {
            width: 25%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
        }
        .three-choose {
            width: 0%;
            display: none;
        }
        .three-choose-move {
            width: 100%;
            animation:three-choose 3s infinite;
            animation-iteration-count: 1;            
        }

        .middle-r {
            margin-left: 2%;
            width: 0%; 
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .upload_style_i {
                margin-top: unset;
                position: relative;
            }
            display: none;
        }
        .middle-r-move {
            width: 73%;
            margin-left: 2%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .upload_style_i {
                margin-top: unset;
                position: relative;
            }
            // animation:middle-r 3s infinite;
            // animation-iteration-count: 1;
        }
        .third-nav {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .table {
                width: 100%;
                border: 1px solid #E4EAF9;
                border-radius: 7px;
                margin-top: 20px;
                display: flex;
                .first-item {
                    width: 18%;
                }
                .second-item {
                    width: 24%;
                }
                .third-item {
                    flex: 1;
                }
                .tab-label {
                    height: 46px;
                    background: #F6F7FC;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1B2742;
                }
                .tab-val {
                    width: 100%;
                    height:80px ;
                }
            }
        }
        .company {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .more-del {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #E4EAF9;
                border-radius: 7px;
                margin-top: 12px;
                padding: 20px;
                box-sizing: border-box;
                position: relative;
                .font {
                    width: 48px;
                    height: 48px;
                    background: #FFFFFF;
                    border: 1px solid #DBDEE6;
                    border-radius: 50%;
                    position: absolute;
                    right: 40px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    &:hover {
                        border-color: #FD5454;
                        .iconfont {
                            color: #FD5454;
                        }
                    }
                    .iconfont {
                        font-size: 26px;
                    }
                }
                .input_nav {
                    flex-wrap: wrap;
                    .wrap {
                        margin-bottom: 10px;
                        margin-right: 20px;
                    }
                    .label {
                        font-size: 14px;
                        font-weight: bold;
                        color: #1B2742;
                    }
                }
                .check-nav {
                    margin-top: 10px;
                    display: flex;
                    .label {
                        font-size: 14px;
                        font-weight: bold;
                        color: #1B2742;
                    }
                    .last-btn {
                        min-width: 100px;
                        height: 30px;
                        padding: 0 5px;
                        background: #FFFFFF;
                        border: 1px solid #4877E8;
                        border-radius: 6px;
                        font-size: 11px;
                        color: #4877E8;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
        .end-btn {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .wrap {
                margin-left: 60px;
                .label {
                font-size: 14px;
                font-weight: bold;
                color: #1B2742;
                }
            }
        }
        .moreSign {
            margin-top: 20px;
            .select-it {
                width: 32%;
                height: 128px;
                background: #FFFFFF;
                border-radius: 12px;
                cursor: pointer;
                border: 2px solid #fff;
                box-sizing: border-box;
                &:hover {
                    border: 2px solid #4877E8;
                }
                .icon-pic {
                    font-size: 40px;
                    color: #4877E8;
                }
                .pic-name {
                    font-size: 21px;
                    font-weight: bold;
                    color: #1B2742;
                    margin-left: 10px;
                }
            }
        }
    }
    .submit-btn {
        width: 100%;
        margin-top: 20px;
        height: 110px;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 12px;
        border: 2px solid #DBDEE6;
        box-sizing: border-box;
        &:hover {
            border: 2px solid #4877E8;
        }
        .iconfont {
            font-size: 50px;
            color: #4877E8;
        }
        span {
            font-size: 28px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 12px;
        }
    }
    .submit-btn-else {
        border: unset;
    }
    .upload_style_i {
        margin-top: 12px;
        .up_del {
            width: 170px;
            height: 228px;
            background: #FFFFFF;
            border: 1px solid #C8CEDA;
            border-radius: 10px;
            .up-img {
                width: 105px;
                height: 110px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .middle-finish {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            .finish-success {
                font-size: 16px;
                font-weight: bold;
                color: #4877E8;
                margin-top: 20px;
            }
            .change-status {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                .see {
                    flex: 1;
                    height: 30px;
                    font-size: 14px;
                    border: 1px solid #DBDEE6;
                    cursor: pointer;
                    &:hover {
                        border-color: #1a9aef;
                        color: #1a9aef;
                    }
                }
            }
        }
        .up-btn {
            width: 90px;
            height: 30px;
            background: #4877E8;
            border-radius: 4px;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
            margin-top: 12px;
        }
    }
    .else_up {
        display: flex;
        .upload_style {
            width: 100px;
            height: 100px;
            border-radius: 4px;
            border: 1px dashed #8a8f97;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
        }
        .pic_wrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: 12px;
        }
        .img_list {
            width: 100px;
            height: 100px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
            .close {
                position: absolute;
                top: -15px;
                right: -15px;
                cursor: pointer;
                color: #dd0000;
                display: none;
            }
        }
        .img_list:hover .close {
            display: inline-block;
        }
    }
    .use-table {
        .pop-inside {
            width: 900px;
            height: 600px;
            background: white;
            border-radius: 8px;
            box-sizing: border-box;
            .choose-it {
                width: 88px;
                height: 34px;
                color: white;
                font-size: 16px;
                background: #4877E8;
                border-radius: 6px;
                position: absolute;
                bottom: 20px;
                right: 24px;
                cursor: pointer;
            }
            .close-it {
                position: absolute;
                right: 12px;
                top: 8px;
                cursor: pointer;
            }
        }
    }
}
.nav-tips{
    font-size:12px;
    font-weight:unset;
    line-height:28px;
    margin-left:10px;
    color: #ff0000;
}
</style>
