import request from '@/utils/request'

/**
 * 直接发起签署获取签章和个人签名
 */
export function contractsignautre (data) {
    return request({
        url: '/contract/signature',
        method: 'post',
        data
    });
}

/**
 * 签署获取签署方信息
 */
export function signdetails (data) {
    return request({
        url: '/contract/sign_details',
        method: 'post',
        data
    });
}

/**
 * 获取短信验证
 */
export function getsms (data) {
    return request({
        url: '/common/getsms',
        method: 'post',
        data
    });
}

/**
 * 验证短信
 */
export function checksms (data) {
    return request({
        url: '/common/checksms',
        method: 'post',
        data
    });
}

/**
 * 签署合同
 */
export function updatesign (data) {
    return request({
        url: '/contract/sign',
        method: 'post',
        data
    });
}

